// src/components/PersonaRenderer.tsx
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading, Textarea, Button, Skeleton, VStack, useToast } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import PersonaServices from '../services/PersonaServices';
import { streamComment } from '../utils/StreamingUtils';  // Import streaming utility function
import { Persona, ChatMessage } from '../types';

interface ExtendedPersonaData extends Persona {
    htmlStructure: string;
}

const PersonaRenderer: React.FC = () => {
    const { personaName, author, version } = useParams<{ personaName: string; author: string; version: string }>();
    const [personaData, setPersonaData] = useState<ExtendedPersonaData | null>(null);
    const [userInput, setUserInput] = useState('');
    const [streamedContent, setStreamedContent] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isStreaming, setIsStreaming] = useState(false);
    const toast = useToast();

    // Ref to accumulate streamed content
    const streamedContentRef = useRef<string>('');

    const personaId = useMemo(() => `${personaName}_${author}_${version}`, [personaName, author, version]);

    useEffect(() => {
        const fetchPersonaData = async () => {
            if (!personaId) return;
            try {
                const data = await PersonaServices.getPersona(personaId, 'web1'); // Fetch for bootstrap version 3
                setPersonaData(data as ExtendedPersonaData);
            } catch (error) {
                console.error('Error fetching persona data:', error);
                toast({
                    title: 'Error',
                    description: 'Failed to fetch persona data',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        };
        fetchPersonaData();
    }, [personaId, toast]);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setUserInput(e.target.value);
    };

    const handleStreamComment = useCallback(async () => {
        if (isStreaming || !personaData) return;

        if (!userInput.trim()) {
            toast({
                title: 'Input Required',
                description: 'Please enter a message before sending.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
            return;
        }

        setIsStreaming(true);
        setStreamedContent('');
        streamedContentRef.current = '';

        const messages: ChatMessage[] = [
            { role: 'system', content: personaData.systemMessage },
            { role: 'user', content: userInput },
        ];

        try {
            // Streaming the conversation
            await streamComment(
                messages,
                (content) => {
                    streamedContentRef.current += content;
                    setStreamedContent(streamedContentRef.current);
                },
                (error) => {
                    console.error('Streaming error:', error);
                    toast({
                        title: 'Streaming Error',
                        description: 'Failed to stream response.',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                        position: 'top-right',
                    });
                    setIsStreaming(false);
                },
                () => {
                    setIsStreaming(false);
                    // Optionally, you can handle any post-stream actions here
                },
                personaData.id,          // Pass the persona ID
                personaData.provider     // Pass the persona provider
            );
        } catch (error) {
            console.error('Error streaming comment:', error);
            toast({
                title: 'Error',
                description: 'Failed to send message.',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right',
            });
            setIsStreaming(false);
        }
    }, [isStreaming, personaData, userInput, toast]);

    if (isLoading) return <Skeleton height="200px" />;

    return (
        <VStack spacing={4} align="stretch">
            <Heading>{personaData?.name}</Heading>
            <Box w="full" dangerouslySetInnerHTML={{ __html: personaData?.htmlStructure || '' }}></Box>
            <Textarea
                value={userInput}
                onChange={handleInputChange}
                placeholder="Type your message..."
                isDisabled={isStreaming}
            />
            <Button onClick={handleStreamComment} isLoading={isStreaming} colorScheme="blue">
                {isStreaming ? 'Streaming...' : 'Send'}
            </Button>
            {streamedContent && (
                <Box w="full" border="1px solid" borderColor="gray.200" borderRadius="md" p={4} mt={4}>
                    <ReactMarkdown
                        rehypePlugins={[rehypeRaw, rehypeKatex]}
                        remarkPlugins={[remarkMath]}
                        components={{
                            // Optional: Customize Markdown components if needed
                        }}
                    >
                        {streamedContent}
                    </ReactMarkdown>
                </Box>
            )}
        </VStack>
    );
};

export default PersonaRenderer;
