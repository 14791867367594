import {Heading, VStack, Link as ChakraLink} from '@chakra-ui/react';
import {Link as RouterLink} from 'react-router-dom';
import {Topics} from '../types';
import slugify from 'slugify';
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import React from "react";

interface Props {
    topics: Topics;
}

const TopicList: React.FC<Props> = ({topics}) => {
    // Sort topics by updated_at in descending order
    const sortedTopics = topics.slice().sort((a, b) => b.updated_at - a.updated_at);

    return (
        <>
            <style>
                {`
                  .custom-katex-vstack .katex {
                    font-size: 1em; 
                    margin-left: 0.05rem; 
                    margin-right: 0.05rem; 
                  }
               `}
            </style>
            <VStack spacing={2} align="stretch" className="custom-katex-vstack">
                <Heading size="md" mt={4}>Topics</Heading>
                {sortedTopics.map((topic, index) => (
                    // Use slugify to generate the slug for the topic message
                    <ChakraLink as={RouterLink}
                                to={`/topic/${topic.id}/${slugify(topic.message, {lower: true, strict: true})}`}
                                key={index}>
                        <ReactMarkdown
                            rehypePlugins={[rehypeRaw, rehypeKatex ]}
                            remarkPlugins={[remarkMath]}
                        >
                            {topic.message}
                        </ReactMarkdown>
                    </ChakraLink>
                ))}
            </VStack>
        </>
    );
};

export default TopicList;
