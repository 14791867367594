// In src/components/NotFoundPage.tsx
import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const NotFoundPage: React.FC = () => {
    return (
        <Box textAlign="center" py={10} px={6}>
            <Text fontSize="48px" fontWeight="bold">
                404
            </Text>
            <Text fontSize="18px" mt={3} mb={2}>
                Page Not Found
            </Text>
            <Text color={'gray.500'} mb={6}>
                The page you're looking for does not seem to exist
            </Text>
        </Box>
    );
};

export default NotFoundPage;
