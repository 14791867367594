import React from 'react';
import {
    Box,
    Link,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
    Text
} from '@chakra-ui/react';

const Disclaimer: React.FC = () => {
    const {isOpen, onOpen, onClose} = useDisclosure();

    return (
        <Box textAlign="center" p={4}>
            <Link onClick={onOpen} color="teal.500" style={{cursor: 'pointer'}}>
                Disclaimer
            </Link>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Disclaimer</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Text mb={4}>
                            All content available on this website, including but not limited to text, graphics, and
                            information, is for general informational purposes only. This content is primarily generated
                            by artificial intelligence algorithms, and while we endeavor to ensure the accuracy and
                            reliability of the content, we make no representations or warranties of any kind, express or
                            implied, about the completeness, accuracy, reliability, suitability, or availability with
                            respect to the website or the information, products, services, or related graphics contained
                            on the website for any purpose. Any reliance you place on such information is therefore
                            strictly at your own risk.
                        </Text>
                        <Text mb={4}>
                            In no event will we be liable for any loss or damage including without limitation, indirect
                            or consequential loss or damage, or any loss or damage whatsoever arising from loss of data
                            or profits arising out of, or in connection with, the use of this website.
                        </Text>
                        <Text>
                            Through this website, you may be able to link to other websites which are not under the
                            control of <Link href="https://personas.ai" isExternal color="teal.500">website</Link>. We
                            have no control over the nature, content, and availability of those sites. The inclusion of
                            any links does not necessarily imply a recommendation or endorse the views expressed within
                            them.
                        </Text>
                        <Text mt={4}>
                            Every effort is made to keep the website up and running smoothly. However, <Link
                            href="https://personas.ai" isExternal color="teal.500">website</Link> takes no
                            responsibility for, and will not be liable for, the website being temporarily unavailable
                            due to technical issues beyond our control.
                        </Text>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Disclaimer;
