import {Topic, Topics, Comment, Comments, CreateCommentType, Operation, TopicComments} from '../types';

type Bindings = {
	DB: D1Database;
};


class CommentServices {

	async getComments() {
		const response = await fetch('/api/getComments');
		const data = await response.json();
		return data as Comments;
	}

	async getTopics() {
		const response = await fetch('/api/getTopics');
		const data = await response.json();
		return data as Topics;
	}

	async getTopic(topicId: string) {
		// Construct the URL with topicId as a query string parameter
		const url = new URL('/api/getTopic', window.location.origin);
		url.searchParams.append('topicId', topicId); // Use 'topicId' as the key for the query string parameter

		const response = await fetch(url.toString());
		console.log(response);
		const data = await response.json();
		console.log(data);
		return data as TopicComments; // Assuming TopicComments is the type that encapsulates the data structure you expect to receive
	}

	async postComment(comment: Comment) {
		const response = await fetch('/api/form', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
			},
			body: JSON.stringify(comment),
		});
		//Parse the response as JSON
		const data = await response.json();
		return data as Comment; //This is the comment that was added to the KV store
	}

	async postTopic(topic: Topic) {
		const response = await fetch('/api/topics', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
			},
			body: JSON.stringify(topic),
		});
		//Parse the response as JSON
		const data = await response.json();
		return data as Topic;
	}

	async updateComment(comment: Comment) {
		const response = await fetch('/api/updateComment', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
			},
			body: JSON.stringify(comment),
		});
		//Parse the response as JSON
		const data = await response.json();
		return data as Comment;
	}

	// The code below sends a request to the Durable Object
	async updateLikesByID(commentID: string, operation: Operation) {
		// const response = await fetch(
		// 	`https://worker-durable.obinnacodes.workers.dev/${commentID}/${operation}`
		// );
		// const data = await response.text();
		// return Number(data);
	}
}

export default new CommentServices();
