import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    FormHelperText,
    VStack,
    Textarea,
    useToast,
} from '@chakra-ui/react';
import commentServices from '../services/commentServices';
import { Topic } from '../types';
import Button from './shared/Button';

// Define proptypes
interface Props {
    handleAddTopic: (topic: Topic) => void;
}

const AddTopic: React.FC<Props> = ({ handleAddTopic }) => {
    const toast = useToast();

    // Adjusted initial state to match the Comment interface structure
    const initialState: Topic = {
        id : 0,
        version_persona_name: '', // Placeholder value
        message: '', // For the comment text
        comments_count: 0,
        created_at: Date.now(), // Current timestamp as placeholder
        updated_at: Date.now(), // Current timestamp as placeholder
    };

    const [values, setValues] = useState<Topic>(initialState);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValues({
            ...values,
            message: e.target.value,
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const topic: Topic = await commentServices.postTopic({
                ...values
            });
            handleAddTopic(topic);
            setValues(initialState);
            toast({
                title: 'Topic added',
                description: 'Your topic has been added to queue',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'top-right',
            });
        } catch (error: any) {
            toast({
                title: 'Error adding comment',
                description: 'Something went wrong',
                variant: 'left-accent',
                position: 'top-right',
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <VStack spacing={8} align="flex-start">
                <FormControl isRequired>
                    <FormLabel htmlFor="message">Topic</FormLabel>
                    <Textarea
                        id="message"
                        name="message"
                        placeholder="Share your topic to be discussed with AI Personas"
                        maxLength={80}
                        height={40}
                        value={values.message}
                        onChange={handleChange}
                    />
                    <FormHelperText>Make the topic as long as you'd like</FormHelperText>
                </FormControl>
                <Button type="submit" disabled={!values.message.trim()}>
                    Send
                </Button>
            </VStack>
        </form>
    );
};

export default AddTopic;
