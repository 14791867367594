import React, { useState, useEffect } from 'react';
import {
    FormControl,
    FormLabel,
    FormHelperText,
    VStack,
    Textarea,
    useToast,
} from '@chakra-ui/react';
import Button from './shared/Button';

interface Message {
    role: string;
    content: string;
}

interface ChatCompletionOptions {
    model?: string;
    max_tokens?: number;
    temperature?: number;
    stop?: string[];
    frequency_penalty?: number;
    presence_penalty?: number;
    top_p?: number;
    top_k?: number;
}

class FlexibleAIClient {
    private baseUrl: string;

    constructor(baseUrl: string = "http://localhost:8087") {
        this.baseUrl = baseUrl;
    }

    async chatCompletion(messages: Message[], options: ChatCompletionOptions = {}): Promise<ReadableStream<Uint8Array> | null> {
        const data = {
            model: options.model || "default_model",
            messages: messages,
            stream: true,
            max_tokens: options.max_tokens || 1566,
            temperature: options.temperature || 0.1,
            stop: options.stop || ["</s>", "User:", "Human:"],
            frequency_penalty: options.frequency_penalty || 0,
            presence_penalty: options.presence_penalty || 0,
            top_p: options.top_p || 0.95,
            top_k: options.top_k || 40,
        };

        try {
            const response = await fetch(`${this.baseUrl}/v1/chat/completions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return response.body;
        } catch (error) {
            console.error("An error occurred:", error);
            return null;
        }
    }
}

interface StreamingChatProps {
    handleAddResponse: (response: string) => void;
}

const StreamingChat: React.FC<StreamingChatProps> = ({ handleAddResponse }) => {
    const toast = useToast();
    const [prompt, setPrompt] = useState('');
    const [response, setResponse] = useState('');
    const [isStreaming, setIsStreaming] = useState(false);

    const client = new FlexibleAIClient();

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPrompt(e.target.value);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsStreaming(true);
        setResponse('');

        const messages: Message[] = [
            { role: "system", content: "You are a helpful assistant." },
            { role: "user", content: prompt }
        ];

        const stream = await client.chatCompletion(messages, { temperature: 0.7 });

        if (stream) {
            const reader = stream.getReader();
            const decoder = new TextDecoder();
            let accumulatedResponse = '';

            try {
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;

                    const chunk = decoder.decode(value);
                    const lines = chunk.split('\n');
                    for (const line of lines) {
                        if (line.startsWith('data: ')) {
                            const data = line.slice(6);
                            if (data === '[DONE]') continue;
                            try {
                                const parsed = JSON.parse(data);
                                if (parsed.choices && parsed.choices[0].delta.content) {
                                    const content = parsed.choices[0].delta.content;
                                    accumulatedResponse += content;
                                    setResponse(prevResponse => prevResponse + content);
                                }
                            } catch (error) {
                                console.error("Error parsing JSON:", error);
                            }
                        }
                    }
                }
            } catch (error) {
                console.error("Error reading stream:", error);
            } finally {
                setIsStreaming(false);
                handleAddResponse(accumulatedResponse);
            }
        } else {
            setIsStreaming(false);
            toast({
                title: 'Error',
                description: 'Failed to get a response from the API.',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right',
            });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <VStack spacing={8} align="flex-start">
                <FormControl isRequired>
                    <FormLabel htmlFor="prompt">Prompt</FormLabel>
                    <Textarea
                        id="prompt"
                        name="prompt"
                        placeholder="Enter your prompt here"
                        value={prompt}
                        onChange={handleChange}
                    />
                    <FormHelperText>Enter a prompt for the AI</FormHelperText>
                </FormControl>
                <Button type="submit" disabled={!prompt.trim() || isStreaming}>
                    {isStreaming ? 'Streaming...' : 'Send'}
                </Button>
                {response && (
                    <FormControl>
                        <FormLabel>Response</FormLabel>
                        <Textarea
                            value={response}
                            readOnly
                            height={200}
                        />
                    </FormControl>
                )}
            </VStack>
        </form>
    );
};

export default StreamingChat;