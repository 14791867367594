import React, { useState } from 'react';
import {
	HStack,
	Text,
	VStack,
	IconButton,
	useDisclosure,
	Button,
	Image,
	Icon,
} from '@chakra-ui/react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { BiExpand } from 'react-icons/bi';
import { FaRegComment } from 'react-icons/fa';
import commentServices from '../services/commentServices';
import { Comment as CommentType, Operation } from '../types';
import CommentDetail from './CommentDetail';
import { timeDifferenceForDate } from '../utils/dateFormatter';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import rehypeKatex from 'rehype-katex'; // Added for math rendering
import rehypeHighlight from 'rehype-highlight';
import 'katex/dist/katex.min.css'; // Ensure to import KaTeX CSS

type ButtonProps = {
	icon: React.ReactNode;
	handleClick: () => void;
	text: number | string;
	disabled: boolean;
};
const CommentIconButton: React.FC<ButtonProps> = ({ icon, handleClick, text, disabled }) => (
	<HStack
		as={Button}
		variant="unstyled"
		align="center"
		spacing={1}
		onClick={handleClick}
		disabled={disabled}
	>
		{icon}
		<Text color="gray.500" fontSize="sm">
			{text}
		</Text>
	</HStack>
);
interface Props {
	comment: CommentType;
}

type NewlineTextProps = {
	children?: React.ReactNode;
};


const NewlineText: React.FC<NewlineTextProps> = ({ children }) => {
	if (typeof children === 'string') {
		return (
			<>
				{children.split('\n').map((line, index) => (
					<React.Fragment key={index}>
						{line}
						{index < children.split('\n').length - 1 && <br />}
					</React.Fragment>
				))}
			</>
		);
	}
	return <>{children}</>;
};

const Comment: React.FC<Props> = ({ comment }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [mainComment, setMainComment] = useState(comment);
	const btnRef = React.useRef<HTMLButtonElement>(null);

	const handleUpdateComment = async (comment = mainComment) => {
		try {
			const updatedComment = await commentServices.updateComment(comment);
			setMainComment(updatedComment);
		} catch (error) {
			console.log('error');
		}
	};

	return (
		<VStack width="100%" height="100%" borderWidth={1} p={4} rounded="md" align="flex-start">
			<HStack align="center" justify="space-between" width="100%">
				<HStack align="start" spacing={3}>
					<VStack align="start" spacing={0}>
						<Text color="orange.300">{mainComment.version_persona_name}</Text>
						<Text as="i" fontSize={{base: 'xs', md: 'sm'}} color="gray.500">
							@{mainComment.version_persona_name}
						</Text>
					</VStack>
				</HStack>
			</HStack>
			<Text my={2} wordBreak="break-word">
				<ReactMarkdown
					children={comment.message}
					remarkPlugins={[remarkGfm, remarkMath]}
					rehypePlugins={[rehypeKatex, rehypeRaw, rehypeHighlight]}
					components={{
						text: ({node, ...props}) => <NewlineText {...props} />,
						a: ({node, ...props}) => <a style={{textDecoration: 'underline', color: 'blue'}} {...props} />,
						pre: ({ node, ...props }) => <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }} {...props} />,
					}}
				/>
			</Text>
		</VStack>
	);
};

export default Comment;
